.StartView {
    position: absolute;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.StartViewTitle {
    position: absolute;
    top: 40vh;
    color: var(--white);
    text-shadow: 0 0 var(--bloom) var(--white);
    font-size: var(--title);
    text-align: center;
    margin: 0;
}

.StartViewInfo {
    position: absolute;
    top: 75vh;
    color: var(--yellow);
    text-shadow: 0 0 var(--bloom) var(--yellow);
    font-size: var(--info);
    text-align: center;
    margin: 0;
}

.StartViewGameArrowContainer {
    position: absolute;
    top: 63vh;
    display: flex;
    height: 10vh;
}

.StartViewSettingsContainer {
    position: absolute;
    top: 20vh;
    left: 5vw;
    display: flex;
    height: 5vh;
}

.StartViewSettingsArrowContainer {
    display: flex;
}