.ArrowContainer {
  display: flex;
  align-items: center;
}

.ArrowContainerArrow {
  height: 10vh;
}

.ArrowContainerGray {
  filter: invert(1) brightness(0.7);
}

.ArrowContainerYellow {
  filter: invert(100%) sepia(100%) saturate(10000%);
}

.ArrowContainerRed {
  filter: invert(100%) sepia(100%) hue-rotate(-60deg) saturate(1000);
}