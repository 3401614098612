.GamePrepare {
    position: absolute;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.GamePrepareTitle {
    position: absolute;
    top: 40vh;
    color: var(--white);
    text-shadow: 0 0 var(--bloom) var(--white);
    font-size: var(--title);
    text-align: center;
    margin: 0;
}

.GamePrepareRoundTitle {
    position: absolute;
    top: 66vh;
    color: var(--white);
    text-shadow: 0 0 var(--bloom) var(--white);
    font-size: var(--info);
    text-align: center;
    margin: 0;
}

.GamePrepareRound {
    position: absolute;
    top: 70vh;
    font-size: var(--score);
    text-align: center;
    margin: 0;
    color: var(--yellow);
    text-shadow: 0 0 var(--bloom) var(--yellow);
}