:root {
  --yellow: yellow;
  --mainColor: yellow;
  --red: red;
  --white: white;
  --green: #679552;
  --blue: #49adc9;
  --gray: #7f7f7f;
  --grayDark: #3f3f3f;
  --background: #252627;

  --title: 15vh;
  --subtitle: 9vh;
  --score: 9vh;
  --info: 4.5vh;
  --tiny: 3vh;

  --bloom: 8px;
  --bloomBox: 0.4em;
  --crt: 4;

  --transition-font-size: 0.2s;
}

body {
  margin: 0;
  font-family: FS Sinclair, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  color: var(--white);
  cursor: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'FS Sinclair';
  src: url('media/FS\ Sinclair\ Medium.otf') format('truetype');
}