.Bloom {
    position: absolute;
    display: flex;
    height: 100vh;
    width: 50vw;
    transform: translateX(50%);
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.BloomTitle {
    margin: 0;
    font-size: var(--subtitle);
    color: var(--white);
    text-shadow: 0 0 var(--bloom) var(--white);
}

.BloomContainer {
    margin-top: 3vh;
    margin-bottom: 3vh;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.BloomTestText {
    font-size: var(--info);
    color: var(--white);
    margin: 0;
    text-shadow: 0 0 var(--bloom) var(--white);
}

.BloomTextYellow {
    color: var(--yellow);
    text-shadow: 0 0 var(--bloom) var(--yellow);
}

.BloomTextWhite {
    color: var(--white);
    text-shadow: 0 0 var(--bloom) var(--white);
}

.BloomTextGray {
    color: var(--gray);
    text-shadow: 0 0 var(--bloom) var(--gray);
}

.BloomTextDarkGray {
    color: var(--grayDark);
    text-shadow: 0 0 var(--bloom) var(--grayDark);
}