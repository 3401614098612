.Data {
    position: absolute;
    display: flex;
    height: 100vh;
    width: 50vw;
    transform: translateX(50%);
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.DataTitle {
    font-size: var(--subtitle);
    color: var(--white);
    text-shadow: 0 0 var(--bloom) var(--white);
}

.DataText {
    font-size: var(--info);
    margin: 1vh 0;
}

.DataTextDarkGray {
    color: var(--grayDark);
    text-shadow: 0 0 var(--bloom) var(--grayDark);
}

.DataTextWhite {
    color: var(--white);
    text-shadow: 0 0 var(--bloom) var(--white);
}

.DataTextYellow {
    color: var(--yellow) !important;
    text-shadow: 0 0 var(--bloom) var(--yellow) !important;
}

.DataTimeBar {
    position: absolute;
    top: 82vh;
    left: 25vw;
    width: 50vw;
    height: 4vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
}