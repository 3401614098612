.SettingsViewNavbar {
    position: absolute;
    top: 10vh;
    left: 1vw;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 2.5vh;
    padding-bottom: 0;
}

.SettingsViewhidden {
    opacity: 0.5;
}

.SettingsViewNavbarStratagem {
    display: flex;
    align-items: center;
    margin-bottom: 3vh;
}

.SettingsViewNavbarStratagem {
    height: 8vh;
}

.SettingsViewNavbarImage {
    height: 100%;
    margin-right: 1vw;
}

.SettingsViewNavbarRed {
    border: 4px solid var(--red);
    background-color: rgba(255, 0, 0, 0.1);
    box-shadow: 0 0 var(--bloomBox) var(--red);
}

.SettingsViewNavbarYellow {
    border: 4px solid var(--yellow);
    background-color: rgba(255, 255, 0, 0.1);
    box-shadow: 0 0 var(--bloomBox) var(--yellow);
}

.SettingsViewNavbarBlue {
    border: 4px solid var(--blue);
    background-color: rgba(20, 60, 255, 0.1);
    box-shadow: 0 0 var(--bloomBox) var(--blue);
}

.SettingsViewNavbarGreen {
    border: 4px solid var(--green);
    background-color: rgba(0, 255, 0, 0.1);
    box-shadow: 0 0 var(--bloomBox) var(--green);
}


.SettingsViewNavbarContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.SettingsViewNavbarTitle {
    font-size: var(--tiny);
    line-height: 4vh;
    margin: 0;
    margin-left: 1vh;
    text-align: left;
    color: var(--white);
    text-shadow: 0 0 var(--bloom) var(--white);
}

.SettingsViewNavbarArrows {
    height: 3.5vh;
}