.Difficulty {
    position: absolute;
    display: flex;
    height: 100vh;
    width: 50vw;
    transform: translateX(50%);
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.DifficultyTitle {
    font-size: var(--subtitle);
    color: var(--white);
    text-shadow: 0 0 var(--bloom) var(--white);
}

.DifficultyText {
    font-size: var(--info);
    color: var(--white);
    text-shadow: 0 0 var(--bloom) var(--white);
}

.DifficultyDivContainer {
    display: flex;
    align-items: center;
}

.DifficultyDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 1.5vw;
    font-size: var(--score);
    color: var(--white);
    text-shadow: 0 0 var(--bloom) var(--white);
    transition: font-size var(--transition-font-size);
}

.DifficultyDivYellow {
    color: var(--yellow);
    text-shadow: 0 0 var(--bloom) var(--yellow);
    font-size: var(--title);
}

.DifficultyDivWhite {
    color: var(--white);
    text-shadow: 0 0 var(--bloom) var(--white);
}

.DifficultyDivGray {
    color: var(--gray);
    text-shadow: 0 0 var(--bloom) var(--gray);
}

.DifficultyDivDarkGray {
    color: var(--grayDark);
    text-shadow: 0 0 var(--bloom) var(--grayDark);
}