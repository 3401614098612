.App {
  text-align: center;

}


.BackgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  filter: grayscale() brightness(0.25);
  background-image: url('./media/icons/superEarth.png');
  background-size: 62vh;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
}

.whiteLine {
  background-color: var(--white);
  box-shadow: 0 0 var(--bloom) var(--white);
  width: 100%;
  height: 6px;
}

.top {
  position: absolute;
  top: 5vh;
}

.bottom {
  position: absolute;
  bottom: 5vh;
}

.GameView {
  position: absolute;
  top: 10vh;
  color: var(--white)
}


@keyframes flicker {
  0% {
    opacity: 0.139305;
  }

  5% {
    opacity: 0.173845;
  }

  10% {
    opacity: 0.11802;
  }

  15% {
    opacity: 0.45313;
  }

  20% {
    opacity: 0.09064;
  }

  25% {
    opacity: 0.419455;
  }

  30% {
    opacity: 0.327915;
  }

  35% {
    opacity: 0.339035;
  }

  40% {
    opacity: 0.132795;
  }

  45% {
    opacity: 0.423465;
  }

  50% {
    opacity: 0.480095;
  }

  55% {
    opacity: 0.04297;
  }

  60% {
    opacity: 0.101565;
  }

  65% {
    opacity: 0.35994;
  }

  70% {
    opacity: 0.267275;
  }

  75% {
    opacity: 0.18644;
  }

  80% {
    opacity: 0.35714;
  }

  85% {
    opacity: 0.352095;
  }

  90% {
    opacity: 0.35015;
  }

  95% {
    opacity: 0.18054;
  }

  100% {
    opacity: 0.121935;
  }
}


@keyframes turn-on {
  0% {
    transform: scale(1, 0.8) translate3d(0, 0, 0);
    -webkit-filter: brightness(30);
    filter: brightness(30);
    opacity: 1;
  }

  3.5% {
    transform: scale(1, 0.8) translate3d(0, 100%, 0);
  }

  3.6% {
    transform: scale(1, 0.8) translate3d(0, -100%, 0);
    opacity: 1;
  }

  9% {
    transform: scale(1.3, 0.6) translate3d(0, 100%, 0);
    -webkit-filter: brightness(30);
    filter: brightness(30);
    opacity: 0;
  }



  11% {
    transform: scale(1, 1) translate3d(0, 0, 0);
    -webkit-filter: contrast(0) brightness(0);
    filter: contrast(0) brightness(0);
    opacity: 0;
  }

  100% {
    transform: scale(1, 1) translate3d(0, 0, 0);
    -webkit-filter: contrast(1) brightness(1.2) saturate(1.3);
    filter: contrast(1) brightness(1.2) saturate(1.3);
    opacity: 1;
  }
}

.crt::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(18, 16, 16, 0.1);
  opacity: 0;
  z-index: 2;
  pointer-events: none;
}

.crt::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%), linear-gradient(90deg, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
  z-index: 2;
  background-size: 100% 4px, 4px 100%;
  pointer-events: none;
  animation: turn-on 6s forwards;
  mix-blend-mode: soft-light;
}