.ExitArrow {
    justify-content: center;
    display: flex;
}

.ExitArrowContainer {
    display: flex;
    align-items: center;
}

.ExitArrow {
    height: 10vh;
}

.ExitArrowBig {
    height: 13vh;
}

.ExitArrowYellow {
    filter: invert(100%) sepia(100%) saturate(10000%);
}

.ExitArrowGray {
    filter: invert(1) brightness(0.4);
}

.ExitArrowWhite {
    filter: invert(1) brightness(0.7);
}

.ExitArrowDarkGray {
    filter: invert(1) brightness(0.2);
}