.SoundContainer {
    position: absolute;
    display: flex;
    height: 100vh;
    width: 50vw;
    transform: translateX(50%);
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.SoundTitle {
    font-size: var(--subtitle);
    color: var(--white);
    text-shadow: 0 0 var(--bloom) var(--white);
    margin: 0;
}

.SoundElement {
    margin-bottom: 3vh;
}

.SoundText {
    font-size: var(--info);
    margin: 1vh;
}

.SoundValue {
    font-size: var(--info);
    margin: 0;
}

.SoundDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 1.5vw;
    font-size: var(--score);
    color: var(--white);
    text-shadow: 0 0 var(--bloom) var(--white);
}

.SoundDivYellow {
    color: var(--yellow);
    text-shadow: 0 0 var(--bloom) var(--yellow);
}

.SoundDivWhite {
    color: var(--white);
    text-shadow: 0 0 var(--bloom) var(--white);
}

.SoundDivGray {
    color: var(--gray);
    text-shadow: 0 0 var(--bloom) var(--gray);
}

.SoundDivDarkGray {
    color: var(--grayDark);
    text-shadow: 0 0 var(--bloom) var(--grayDark);
}