.MultipleArrowSubscriber {
    display: flex;
    align-items: center;
    height: 100%;
}

.MultipleArrowSubscriberGray {
    filter: invert(1) brightness(0.7);
}

.MultipleArrowSubscriberYellow {
    filter: invert(100%) sepia(100%) saturate(10000%);
}

.MultipleArrowSubscriberDarkGray {
    filter: invert(1) brightness(0.3);
}

.MultipleArrowSubscriberArrow {
    height: 100%;
}

.MultipleArrowSubscriberDiv {
    height: 100%;
}