.About {
    position: absolute;
    display: flex;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
    cursor: url("../../media/icons/res.svg"), auto;
}

.AboutTextContainer {
    width: 50vw;


    a {
        color: var(--yellow);
        text-shadow: 0 0 var(--bloom) var(--yellow);
        text-decoration: none;
    }

    a:hover {
        cursor: url("../../media/icons/resHover.svg"), auto;
    }
}

.AboutTitle {
    font-size: var(--subtitle);
    color: var(--white);
    text-shadow: 0 0 var(--bloom) var(--white);
    margin: 0;
}

.AboutText {
    font-size: var(--info);
    color: var(--white);
    text-shadow: 0 0 var(--bloom) var(--white);
}

.AboutInfo {
    font-size: var(--tiny);
    color: var(--white);
    text-shadow: 0 0 var(--bloom) var(--white);
}